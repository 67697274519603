/* App.css */
.view360-container .view360-hotspot::before {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  left: -10px;
  width: 30px; /* Increase the size of the hotspot circle */
  height: 30px; /* Increase the size of the hotspot circle */
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 0.8); /* Set the hotspot color */
  animation: blink 1s infinite; /* Add the blinking animation */
}

@keyframes blink {
  0%, 50% {
    opacity: 1;
  }
  51%, 100% {
    opacity: 0;
  }
}


.div-warp {
  max-width: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.view360-canvas {
  width: 100%;
  height: 100vh;
}

.view360-container .view360-hotspot::before {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  left: -10px;
  width: 175px;
  height: 175px;
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 0.8);
  animation: blink 1s infinite;
}
.loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #09080D;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  font-size: 24px;
  z-index: 10;
}